<template>
    <v-container class="my-16">
        <v-row align="center" align-content="space-between" class="my-12">
            <v-col cols="12" sm="6" md="4" class="align-center">
                <h3
                    class="text-left text-act-blue text-lg-h5 text-sm-h6 text-h6 font-weight-light"
                >
                    <span class="text-uppercase">Servicios</span>
                </h3>
                <h1
                    class="text-left text-act-blue text-xl-h3 text-lg-h4 text-md-h4 text-sm-h4 text-h4 font-weight-bold pb-4"
                >
                    Lo Que Hacemos
                </h1>
                <v-divider></v-divider>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
                v-for="card in cards"
                :key="card.id"
                class="d-flex align-self-stretch"
            >
                <v-card>
                    <v-img
                        class="white--text align-end mb-3"
                        :src="getImg(card.image)"
                        aspect-ratio="2"
                    ></v-img>
                    <v-card-subtitle
                        class="pb-0 text-uppercase font-weight-regular text-act-light-blue text-h6 text-sm-subtitle-2"
                        >{{ card.subtitle }}</v-card-subtitle
                    >
                    <v-card-title
                        class="pt-0 text-no-wrap text-act-blue text-h4 text-sm-h5 text-md-h6 text-lg-h5 text-xl-h4 font-weight-regular text-uppercase"
                        >{{ card.title }}</v-card-title
                    >

                    <v-card-text class="pb-12">
                        {{ card.description }}
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            text
                            absolute
                            bottom
                            left
                            color="light-blue lighten-2"
                            v-if="!reveal"
                            @click="revealContent(card.id)"
                            >VER M&Aacute;S</v-btn
                        >
                        <v-spacer></v-spacer>

                        <v-btn
                            icon
                            absolute
                            bottom
                            right
                            @click="revealContent(card.id)"
                        >
                            <v-icon color="light-blue lighten-1">{{
                                reveal ? 'mdi-chevron-up' : 'mdi-chevron-down'
                            }}</v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-expand-transition v-if="cid === card.id">
                        <div v-show="reveal">
                            <v-divider></v-divider>

                            <v-card-text class="pb-12">
                                <v-list-item
                                    v-for="service in card.services"
                                    :key="service.title"
                                    class="px-0"
                                >
                                    <v-list-item-icon class="mr-0">
                                        <v-icon
                                            small
                                            color="light-blue darken-1"
                                        >
                                            mdi-check-all
                                        </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">{{
                                            service.title
                                        }}</v-list-item-title>
                                        <v-list-item
                                            class="pr-0"
                                            v-for="list in service.list"
                                            :key="list.item"
                                        >
                                            <v-list-item-icon class="mr-0">
                                                <v-icon
                                                    small
                                                    color="light-blue darken-1"
                                                >
                                                    mdi-check
                                                </v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content class="pb-1">
                                                <v-list-item-subtitle
                                                    class="text-wrap"
                                                >
                                                    {{ list.item }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card-text>
                        </div>
                    </v-expand-transition>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    props: ['breakpoint-name'],
    data() {
        return {
            breakpoint: this.breakpointName,
            reveal: false,
            cid: '00',
            height: '100%',
            cards: [
                {
                    id: '01',
                    image: 'asesorias_contables.webp',
                    title: 'Contables',
                    subtitle: 'Asesorías',
                    description:
                        'Mantenemos los registros contables, de compra y venta, remuneraciones, honorarios, balances y estados financieros.',
                    services: [
                        {
                            title:
                                'INICIO DE ACTIVIDADES, R.U.T., PATENTES COMERCIALES PROCESO CONTABLE',
                            list: [
                                {
                                    item: 'Registro Operaciones'
                                },
                                {
                                    item: 'Emisión de Libros Legales'
                                }
                            ]
                        },
                        {
                            title: 'EMISIÓN DE INFORMES',
                            list: [
                                {
                                    item: 'Balances'
                                },
                                {
                                    item: 'Estados de Resultados'
                                },
                                {
                                    item: 'Otros Informes'
                                }
                            ]
                        },
                        {
                            title: 'INFORMES DE GESTIÓN',
                            list: [
                                {
                                    item: 'Estado de Situación'
                                },
                                {
                                    item: 'Informes para Entidades Financieras'
                                },
                                {
                                    item: 'Informes de Gestión'
                                },
                                {
                                    item: 'Índices'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: '02',
                    image: 'asesorias_tributarias.webp',
                    title: 'Tributarias',
                    subtitle: 'Asesorías',
                    description:
                        'Orientamos a nuestros clientes en el cumplimiento de las Normas del S.I.I., Tesorería, u otros estamentos y optimizamos los Beneficios Tributarios a favor de nuestros clientes.',
                    services: [
                        {
                            title: 'IMPUESTO AL VALOR AGREGADO (I.V.A.)',
                            list: [
                                {
                                    item: 'Determinación de I.V.A.'
                                },
                                {
                                    item: 'Informes para Entidades Financieras'
                                },
                                {
                                    item: 'Declaración de Pago'
                                }
                            ]
                        },
                        {
                            title: 'IMPUESTO A LA RENTA',
                            list: [
                                {
                                    item: 'Determinación Impuesto Renta'
                                },
                                {
                                    item: 'Declaración de Impuesto'
                                },
                                {
                                    item: 'Confección FUT'
                                },
                                {
                                    item: 'Declaraciones Juradas'
                                },
                                {
                                    item: 'Emisión de Certificados'
                                }
                            ]
                        },
                        {
                            title: 'GESTIÓN ANTE S.I.I.',
                            list: [
                                {
                                    item: 'Rectificatoria de Declaraciones'
                                },
                                {
                                    item:
                                        'Solicitud de devolución Impuestos Pagados en excesos'
                                },
                                {
                                    item:
                                        'Aclaración de Notificaciones y Citaciones ante S.I.I.'
                                },
                                {
                                    item: 'Gestión de Termino de Giro'
                                },
                                {
                                    item:
                                        'Gestión de Suspensión Temporal de actividades'
                                }
                            ]
                        },
                        {
                            title: 'AUDITORIAS TRIBUTARIAS',
                            list: [
                                {
                                    item: 'Auditorias preventivas de Impuestos'
                                }
                            ]
                        },
                        {
                            title: 'DIAGNOSTICO DE SITUACION TRIBUTARIA'
                        }
                    ]
                },
                {
                    id: '03',
                    image: 'recursos_humanos.webp',
                    title: 'Recursos Humanos',
                    subtitle: 'Asesorías y Gestión',
                    description:
                        'Orientamos a nuestros clientes en recursos humanos, consultoría y asesoría en el cumplimiento de las Normas Laborales Vigentes.',
                    services: [
                        {
                            title:
                                'Contratos de Trabajo, sus modificaciones y actualizaciones.'
                        },
                        {
                            title: 'Proceso de Liquidaciones de Sueldos.'
                        },
                        {
                            title: 'Proceso de Leyes Sociales.'
                        },
                        {
                            title: 'Tramitación Licencias Médicas.'
                        },
                        {
                            title: 'Emisión de Informes para la Contabilidad.'
                        },
                        {
                            title: 'Confección Reglamentos Internos.'
                        },
                        {
                            title: 'Otras actividades de Recursos Humanos.'
                        }
                    ]
                },
                {
                    id: '04',
                    image: 'administracion.webp',
                    title: 'Administración',
                    subtitle: 'Asesorías y Gestión',
                    description:
                        'Orientamos en el control hacia el cumplimiento de los Objetivos Financieros y Económicos de la Empresa. Nos encargamos de toda la gestión administrativa, contable y financiera de su empresa.',
                    services: [
                        {
                            title: 'Emisión de Flujos de Caja'
                        },
                        {
                            title: 'Presupuestos de Estados de Resultados'
                        },
                        {
                            title: 'Evaluaciones Económicas de la Empresa'
                        },
                        {
                            title:
                                'Diagnósticos Financieros, Contables y Tributarios'
                        }
                    ]
                },
                {
                    id: '05',
                    image: 'asesorias_y_servicios_tributarios.webp',
                    title: 'Tributarios',
                    subtitle: 'Asesorías y Servicios',
                    description:
                        'Nos ocupamos de que se cumplan todas las obligaciones tributarias requeridas, asesoramos permanentemente logrando optimizar recursos, minimizar riesgos y fortalecer el area tributaria de su empresa.',
                    services: [
                        {
                            title:
                                'Migración regímenes tributarios, ( 14 A, 14 B, 14 Ter)'
                        },
                        {
                            title:
                                'Planificación Tributaria: Nacional e Internacional'
                        },
                        {
                            title:
                                'Plan de Cumplimiento tributario, (mensual, anual)'
                        },
                        {
                            title: 'Tributación del Impuesto a la Herencia'
                        },
                        {
                            title: 'Modernización Tributaria, Ley 21.210'
                        }
                    ]
                }
            ]
        };
    },
    methods: {
        getImg(img) {
            return require('../assets/' + img);
        },
        minHeight() {
            if (this.breakpoint === 'xs') {
                this.height = '500';
            } else if (this.breakpoint === 'sm') {
                this.height = '330';
            } else {
                this.height = '430';
            }
            return this.height;
        },
        revealContent(id) {
            this.cid = id;
            this.reveal = !this.reveal;
            return this.reveal;
        }
    },
    computed: {
        getMinHeight() {
            return this.minHeight();
        }
    }
};
</script>

<style lang="scss" scoped>
.v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
}
</style>
