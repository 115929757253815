<template>
    <section class="py-16 top-banner">
        <v-container class="my-12 my-xs-6 my-sm-8">
            <v-row
                align="center"
                justify="center"
                class="py-xs-10 py-sm-14 py-md-16"
            >
                <v-col cols="12" sm="6" xl="4">
                    <v-img
                        src="../assets/logo-act.png"
                        contain
                        :width="logoSize()"
                        class="mx-auto"
                    ></v-img>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    offset-xl="1"
                    xl="4"
                    class="px-10 px-sm-6"
                >
                    <v-row
                        justify="center"
                        justify-sm="start"
                        justify-md="start"
                        justify-lg="start"
                        align="center"
                        class="pt-16 pt-sm-0 pt-md-0 pt-lg-0"
                    >
                        <v-col cols="12">
                            <h3
                                class="text-left text-act-blue text-lg-h4 text-sm-h5 text-h5 font-weight-light"
                            >
                                Soluciones
                            </h3>
                            <h1
                                class="text-left text-act-blue text-xl-h3 text-lg-h3 text-md-h4 text-sm-h4 text-h4 font-weight-bold"
                            >
                                Contables y Tributaria
                            </h1>
                            <p class="font-weight-light my-6">
                                Nos dedicamos a dar el mejor soporte a las
                                Pymes, brindando soluciones constantes para que
                                tengan apoyo en temas contables y tributarios,
                                se ocupe en aumentar cada vez más su
                                productividad, dejando en manos expertas su área
                                financiera y tributaria.
                            </p>
                            <div class="text-center text-sm-left">
                                <v-btn
                                    raised
                                    large
                                    :x-large="$vuetify.breakpoint.name === 'xl'"
                                    color="light-blue darken-2 white--text"
                                    @click="section('services')"
                                >
                                    Ver Servicios
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
export default {
    props: ['breakpoint-name'],
    data() {
        return {
            logoBanner: '280',
            breakpoint: this.breakpointName
        };
    },
    methods: {
        logoSize() {
            const size = this.breakpoint;
            // console.log('ScreenSize: ' + size);
            if (size === 'sm' || size === 'md') {
                this.logoBanner = '320';
            } else if (size === 'lg') {
                this.logoBanner = '380';
            } else if (size === 'xl') {
                this.logoBanner = '440';
            } else {
                this.logoBanner = '280';
            }
            return this.logoBanner;
        },
        section(param) {
            if (param) this.$emit('section-event', param);
        }
    }
};
</script>

<style scoped>
.top-banner {
    background-color: #f1f3f7;
}
</style>
