<template>
    <v-container class="my-16 px-6">
        <v-row class="my-12">
            <v-col cols="12">
                <h3
                    class="text-left text-act-blue text-lg-h4 text-sm-h5 text-h5 font-weight-light"
                >
                    <span class="font-weight-regular">ACT</span>Contadores
                </h3>
                <h1
                    class="text-left text-act-blue text-xl-h3 text-lg-h3 text-md-h4 text-sm-h4 text-h4 font-weight-bold"
                >
                    Quienes Somos
                </h1>
                <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="7" class="">
                <p>
                    Debido a los constantes cambios en el mercado nace ACT
                    CONTADORES, para ayudar a las personas naturales, pequeñas y
                    medianas empresas (PYMES).
                </p>
                <h4 class="font-weight-light">Mision</h4>
                <p>
                    Entregar a nuestros clientes una completa asesoría contable
                    y asesoría tributaria, además de dar las facilidades de
                    desempeño en el área administrativa.
                </p>
                <h4 class="font-weight-light">Vision</h4>
                <p>
                    Hacer que las PYMES emerjan de manera productiva y rentable.
                </p>
                <h4 class="font-weight-light">Objetivo</h4>
                <p>
                    Hacer que las Pymes cuenten con el apoyo y soporte constante
                    en temas contables y tributarios, ocupándonos
                    estratégicamente de su labor financiera
                </p>
            </v-col>
            <v-col cols="12" md="5" class="px-8 px-sm-0">
                <v-card flat shape>
                    <v-img
                        src="../assets/shaking-hands.webp"
                        max-width="640"
                        class="mx-auto"
                    >
                    </v-img>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    props: ['breakpoint-name'],
    data() {
        return {
            image: '450'
        };
    },
    methods: {
        imageSize() {
            const size = this.breakpointName;
            if (size === 'sm') {
                this.image = '300';
            } else if (size === 'md') {
                this.image = '320';
            } else if (size === 'lg') {
                this.image = '380';
            } else if (size === 'xl') {
                this.image = '500';
            } else {
                this.image = '100';
            }
            return this.image;
        }
    }
};
</script>

<style lang="scss" scoped></style>
