<template>
    <v-footer padless color="light-blue darken-5 py-10 px-5">
        <v-row align="center" justify="center">
            <v-row justify="center" no-gutters>
                <v-col cols="12">
                    <h1
                        class="text-center text-h5 white--text font-weight-bold"
                    >
                        V&iacute;nculos de Inter&eacute;s
                    </h1>
                </v-col>
                <v-col cols="12" class="text-center">
                    <v-btn
                        v-for="link in links"
                        :key="link"
                        color="white"
                        text
                        rounded
                        class="my-2 text-caption"
                    >
                        {{ link }}
                    </v-btn>
                </v-col>
                <v-col class="my-6" cols="12">
                    <v-divider class="light-blue lighten-2"></v-divider>
                </v-col>
                <v-col
                    class="pb-3 text-center white--text font-size-0-8"
                    cols="12"
                >
                    {{ new Date().getFullYear() }} —
                    <strong
                        >Todos los Derechos Reservados &copy;ACT
                        Contadores</strong
                    >
                </v-col>
            </v-row>
        </v-row>
    </v-footer>
</template>

<script>
export default {
    data: () => ({
        links: [
            'Servicio de Impuestos Internos',
            'Instituto de Pervisión Social',
            'Superintendencia de Pensiones ',
            'Ministerio de Hacienda',
            'Tesoreria General'
        ]
    })
};
</script>

<style></style>
