import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "material-design-icons-iconfont";

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: "md",
	},
});
