<template>
    <section>
        <v-app-bar dense light elevate-on-scroll app>
            <v-app-bar-nav-icon
                color="light-blue darken-4"
                v-if="$vuetify.breakpoint.smAndDown"
                @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
            <v-toolbar-title class="">
                <span class="light-blue--text text--darken-4 font-weight-bold"
                    >ACT</span
                >
                <span class="light-blue--text text--darken-4"
                    >Contadores</span
                ></v-toolbar-title
            >
            <v-spacer></v-spacer>
            <nav v-if="$vuetify.breakpoint.mdAndUp">
                <v-btn
                    plain
                    text
                    elevate="0"
                    @click="section('about')"
                    class="light-blue--text text--darken-4"
                    >Quienes Somos</v-btn
                >
                <v-btn
                    plain
                    text
                    elevate="0"
                    @click="section('services')"
                    class="light-blue--text text--darken-4"
                    >Servicios</v-btn
                >
                <v-btn
                    plain
                    text
                    elevate="0"
                    @click="section('contact')"
                    class="light-blue--text text--darken-4"
                    >Contacto</v-btn
                >
            </nav>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" fixed temporary>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>ACT Contadores</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense>
                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon color="blue">keyboard_arrow_right</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title @click="section('about')"
                            >Quienes Somos</v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon color="blue">keyboard_arrow_right</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title @click="section('services')"
                            >Servicios</v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon color="blue">keyboard_arrow_right</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title @click="section('contact')"
                            >Contacto</v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>

                <!-- <v-divider inset></v-divider> -->
            </v-list>
            <v-divider class="my-5"></v-divider>
            <v-list dense>
                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon color="blue">
                            phone
                        </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            <a
                                href="tel://+56226387079"
                                class="text-decoration-none"
                            >
                                +56 2 2638 70 79
                            </a>
                        </v-list-item-title>
                        <v-list-item-subtitle>Fijo</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon color="blue">
                            smartphone
                        </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            <a
                                href="tel://+56991640966"
                                class="text-decoration-none"
                            >
                                + 56 9 9164 09 66
                            </a>
                        </v-list-item-title>
                        <v-list-item-subtitle>Celular</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-icon>
                        <v-icon small color="blue">mdi-message-text</v-icon>
                    </v-list-item-icon>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon color="blue">
                            map-marker
                        </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title
                            >Estado 42, Of. 602</v-list-item-title
                        >
                        <v-list-item-subtitle
                            >Santiago, Centro</v-list-item-subtitle
                        >
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon color="blue">
                            map
                        </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            <a
                                href="https://goo.gl/maps/o19d36TeJqqHy19g7"
                                class="text-decoration-none"
                                target="_blank"
                            >
                                Ver Mapa</a
                            >
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </section>
</template>

<script>
export default {
    data() {
        return {
            drawer: false,
            group: null
        };
    },
    methods: {
        section(param) {
            this.drawer = false;
            if (param) this.$emit('section-event', param);
        }
    },
    watch: {
        group() {
            this.drawer = false;
        }
    }
};
</script>

<style></style>
