<template>
    <section class="my-16">
        <v-carousel
            cycle
            height="400"
            hide-delimiter-background
            hide-delimiters
            show-arrows-on-hover
        >
            <v-carousel-item v-for="(t, i) in testimonials" :key="i">
                <v-sheet :color="t.bgColor" height="100%">
                    <v-row class="fill-height" align="center" justify="center">
                        <v-col cols="10" sm="8" md="5" lg="4">
                            <div>
                                <v-icon x-large :class="t.txtColor"
                                    >mdi-format-quote-open</v-icon
                                >
                            </div>
                            <div
                                class="text-body-1 font-italic font-weight-regular pb-4"
                                :class="t.txtColor"
                            >
                                {{ t.testimonial }}
                            </div>
                            <div
                                class="text-caption font-italic font-weight-light"
                                :class="t.txtColor"
                            >
                                -- {{ t.company }}
                            </div>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-carousel-item>
        </v-carousel>
    </section>
</template>

<script>
export default {
    data: () => ({
        model: 0,
        colors: [
            'light-blue lighten-2',
            'secondary',
            'yellow darken-2',
            'red',
            'orange'
        ],
        cardColor: 'transparent',
        testimonials: [
            {
                id: '01',
                bgColor: 'light-blue lighten-5',
                bgCardColor: 'light-blue lighten-4',
                txtColor: 'light-blue--text text--darken-4',
                company: 'AROMEX S.A.',
                testimonial:
                    'Trabajamos hace ya muchos años con ACT Contadores, siempre han estado atentos en responder y solucionar nuestras inquietudes y estamos felices de tener a un gran aliado'
            },
            {
                id: '02',
                bgColor: 'light-blue darken-1',
                bgCardColor: 'light-blue lighten-1',
                txtColor: 'white--text',
                company: 'Inversiones San Vicente SpA',
                testimonial:
                    'ACT Contadores son un apoyo estratégico y gracias a su compromiso hemos podido dedicar más tiempo a aumentar las ventas, dejándo el área contable completamente en sus manos.'
            },
            {
                id: '03',
                bgColor: 'light-blue darken-4',
                bgCardColor: 'light-blue darken-3',
                txtColor: 'white--text',
                company: 'Importadora FG Ltda',
                testimonial:
                    'Delegar en ACT Contadores ha sido una de las mejores decisiones que hemos tomado desde que comenzamos nuestro emprendimiento. .'
            }
        ]
    })
};
</script>

<style></style>
