import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import * as GmapVue from 'gmap-vue';
import '@babel/polyfill';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.config.productionTip = false;

Vue.use(GmapVue, {
    load: {
        key: 'AIzaSyA-EKYzliStSDiCUpd1ZIZcPzhWEzjTjaI',
        libraries: 'places'
    },
    installComponents: true
});

Vue.use(VueAxios, axios);

new Vue({
    vuetify,
    render: h => h(App)
}).$mount('#app');
