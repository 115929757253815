<template>
    <section class="light-blue lighten-5 my-16">
        <v-container class="my-16 px-6">
            <v-row align="center" justify="space-around" class="my-12">
                <v-col cols="12" sm="7">
                    <v-card elevation="0" color="transparent" class="mx-auto">
                        <v-card-text>
                            <h1
                                class="text-center text-sm-left text-act-blue text-h6 text-lg-h4 font-weight-bold text-uppercase"
                            >
                                Soluciones Contables y Tributarias
                            </h1>
                            <p
                                class="text-center text-sm-left text-act-blue font-weight-light text-subtitle-1"
                            >
                                Contabilidad para Personas Naturales, Pequeñas y
                                Medianas Empresas (Pymes)
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="4" class="text-center text-sm-left">
                    <v-card elevation="0" color="transparent" class="mx-auto">
                        <v-card-text>
                            <v-btn
                                color="light-blue darken-2 white--text"
                                large
                                :x-large="$vuetify.breakpoint.name === 'xl'"
                                @click="section('contact')"
                                >Pongase en Contacto</v-btn
                            >
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
export default {
    props: ['breakpoint-name'],
    data() {
        return {
            breakpoint: this.breakpointName
        };
    },
    methods: {
        section(param) {
            if (param) this.$emit('section-event', param);
        }
    }
};
</script>

<style></style>
