<template>
    <section class="section mt-16">
        <GmapMap
            :center="{ lat: -33.442721, lng: -70.6492433 }"
            :zoom="17"
            map-type-id="terrain"
            class="google-map-style"
        >
            <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="center = m.position"
            />
        </GmapMap>
    </section>
</template>

<script>
export default {
    data() {
        return {
            markers: [
                {
                    position: {
                        lat: -33.442721,
                        lng: -70.6492433
                    }
                }
            ]
        };
    }
};
</script>

<style scoped>
.section {
    background-color: #00b0ff;
}

.google-map-style {
    width: 100%;
    height: 400px;
    border-top: 1px #00b0ff solid;
    border-bottom: 1px #00b0ff solid;
}
</style>
