<template>
    <v-container class="mt-16 pt-16">
        <v-row align="start">
            <v-col cols="12" sm="6" offset-lg="1" lg="5">
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="submitForm"
                >
                    <v-text-field
                        v-model.trim="name"
                        :counter="50"
                        :rules="nameRules"
                        label="Nombre Completo"
                        required
                        color="light-blue darken-4"
                        class="text-act-blue"
                    ></v-text-field>

                    <v-text-field
                        v-model.trim="email"
                        :rules="emailRules"
                        label="E-mail"
                        required
                        class="light-blue--text text--darken-4"
                    ></v-text-field>

                    <v-textarea
                        v-model.trim="message"
                        :counter="160"
                        label="Mensaje"
                        :rules="messageRules"
                        rows="3"
                        required
                        class="light-blue--text text--darken-4"
                    ></v-textarea>

                    <vue-recaptcha
                        ref="recaptcha"
                        @verify="onVerify"
                        :sitekey="google_captcha_site_key"
                        :loadRecaptchaScript="true"
                    >
                    </vue-recaptcha>

                    <v-btn
                        :loading="loader"
                        :disabled="!valid || !robot"
                        color="light-blue lighten-2"
                        class="mr-4 mt-8 upercase"
                        @click="validate"
                        light
                        outlined
                        tile
                        block
                        x-large
                    >
                        {{ buttonText }}
                    </v-btn>
                </v-form>
            </v-col>
            <v-col cols="12" sm="6" offset-lg="1" lg="5">
                <v-card class="mx-auto" elevation="0" plain>
                    <v-card-title>
                        <v-icon left color="light-blue darken-4">
                            mdi-office-building-marker
                        </v-icon>
                        <span
                            class="title font-weight-light light-blue--text text--darken-4"
                            >Direcci&oacute;n</span
                        >
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="font-weight-regular text-body1">
                        <v-list>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="light-blue darken-2"
                                        >mdi-map-marker</v-icon
                                    >
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title
                                        >Estado 42, Oficina
                                        602</v-list-item-title
                                    >
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-action></v-list-item-action>
                                <v-list-item-content class="pt-0">
                                    <v-list-item-title
                                        >Santiago, Centro</v-list-item-title
                                    >
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider inset></v-divider>
                            <v-list-item href="tel://+56991640966">
                                <v-list-item-icon>
                                    <v-icon color="light-blue darken-2"
                                        >mdi-cellphone</v-icon
                                    >
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title
                                        >+56 9 9164 09 66</v-list-item-title
                                    >
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item href="tel://+56226387079">
                                <v-list-item-icon>
                                    <v-icon color="light-blue darken-2"
                                        >mdi-phone</v-icon
                                    >
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        +56 2 2638 7079
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider inset></v-divider>
                            <v-list-item
                                href="https://goo.gl/maps/o19d36TeJqqHy19g7"
                                target="_blank"
                            >
                                <v-list-item-icon>
                                    <v-icon color="light-blue darken-2"
                                        >mdi-subway-variant</v-icon
                                    >
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title
                                        >A pasos del
                                        <strong>Metro</strong> Universidad de
                                        Chile</v-list-item-title
                                    >
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
const axios = require('axios');

import VueRecaptcha from 'vue-recaptcha';

export default {
    components: {
        VueRecaptcha
    },
    data: () => ({
        robot: false,
        valid: true,
        google_captcha_site_key: '6LdYLGMaAAAAAB_r9DHrz2dY8gqwhzJmf0_NjJVi',
        name: '',
        nameRules: [
            v => !!v || 'Escriba su Nombre Completo',
            v => (v && v.length <= 50) || 'No debe superar los 100 caracteres'
        ],
        email: '',
        emailRules: [
            v => !!v || 'E-mail es obligatorio',
            v => reg.test(v) || 'Ingrese un E-mail valido'
        ],
        message: '',
        messageRules: [
            v => !!v || 'Ingrese el motivo de contacto',
            v => v.length <= 150 || 'Max 150 caracteres'
        ],
        loader: false,
        buttonText: 'enviar'
    }),

    methods: {
        validate() {
            this.$refs.form.validate();
            const valid = this.$refs.form.validate();
            this.loader = valid ? true : false;
            this.loader = this.submitForm() ? false : true;
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        submitForm() {
            axios
                .post(
                    'https://api.actcontadores.cl/contact',
                    {
                        name: this.name,
                        email: this.email,
                        message: this.message
                    },
                    {
                        headers: {
                            'content-type':
                                'application/x-www-form-urlencoded;charset=utf-8'
                        }
                    }
                )
                .then(response => {
                    if (response.status === 202) {
                        this.loader = false;
                        this.name = '';
                        this.email = '';
                        this.message = '';
                        this.robot = false;
                        this.buttonText = 'mensaje enviado';
                        this.resetValidation();
                    }
                })
                .catch(function() {
                    this.loader = false;
                    this.name = '';
                    this.email = '';
                    this.message = '';
                    this.robot = false;
                    this.buttonText = 'error al enviar mensaje';
                    this.resetValidation();

                    alert(
                        'Hubo un error al enviar el formulario, refresque el sitio y vuelvalo a intentar'
                    );
                });
            return true;
        },
        onVerify: function(response) {
            if (response) this.robot = true;
        }
    },
    watch: {
        robot() {
            this.robot;
        },
        buttonText() {
            this.buttonText;
        },
        buttonColor() {
            this.buttonColor;
        }
    }
};
</script>

<style scoped>
textarea {
    resize: none;
}
.v-list-item {
    min-height: 1px;
}
</style>
