<template>
    <v-app class="px-2">
        <top-navigation @section-event="goToSection"></top-navigation>
        <v-main>
            <top-banner
                id="top-banner"
                @section-event="goToSection"
                :breakpoint-name="getBreakpoint()"
            ></top-banner>
            <section ref="about" id="about" class="my-16">
                <section-about
                    :breakpoint-name="getBreakpoint()"
                ></section-about>
            </section>

            <ribbon-contact
                @section-event="goToSection"
                :breakpoint-name="getBreakpoint()"
            ></ribbon-contact>
            <section ref="services" id="services" class="my-16">
                <section-services
                    :breakpoint-name="getBreakpoint()"
                ></section-services>
            </section>
            <ribbon-testimonials></ribbon-testimonials>
            <section ref="contact" id="contact" class="mt-16">
                <section-contact
                    :breakpoint-name="getBreakpoint()"
                ></section-contact>
            </section>
            <google-map></google-map>

            <site-footer></site-footer>
            <v-btn
                fab
                color="green accent-4 white--text"
                bottom
                right
                fixed
                href="https://wa.me/56991640966?text=Hola%20Mi%20nombre%20es%20[Ingrese%20su%20nombre]"
                target="_blank"
            >
                <v-icon>mdi-whatsapp</v-icon>
            </v-btn>
        </v-main>
    </v-app>
</template>

<script>
// import { component } from 'vue/types/umd';
import TopNavigation from './components/TopNavigation.vue';
import TopBanner from './components/TopBanner.vue';
import SectionAbout from './components/SectionAbout.vue';
import RibbonContact from './components/RibbonContact.vue';
import SectionServices from './components/SectionServices.vue';
import RibbonTestimonials from './components/RibbonTestimonials.vue';
import SectionContact from './components/SectionContact.vue';
import GoogleMap from './components/GoogleMap.vue';
import SiteFooter from './components/SiteFooter.vue';

export default {
    data() {
        return {
            section: '',
            breakpoint: ''
        };
    },
    components: {
        TopNavigation,
        TopBanner,
        SectionAbout,
        RibbonContact,
        SectionServices,
        RibbonTestimonials,
        SectionContact,
        GoogleMap,
        SiteFooter
    },
    methods: {
        getBreakpoint() {
            this.breakpoint = this.size;
            return this.breakpoint;
        },
        goToSection(section) {
            this.section = this.$refs[section];
        }
    },
    computed: {
        size() {
            const size = this.$vuetify.breakpoint.name;
            return size;
        }
    },
    watch: {
        section() {
            if (this.section) {
                this.section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    },
    created() {
        document.title =
            'ACT Contadores - Contabilidad - Impuestos - Tributaria - Administracion - Asesorias Contables - Asesorias Tributarias - Gestion - Balance - Declaracion Renta';
    }
};
</script>

<style lang="scss">
$act-blue: #153069;
$act-light-blue: #8a97b4;

body {
    font-family: 'Roboto', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #333;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 400;
}
.v-application {
    font-family: 'Roboto', sans-serif !important;
}

/* Font Size font-size- */
@for $i from 0 through 2 {
    @for $j from 0 through 9 {
        .font-size-#{$i}-#{$j} {
            font-size: $i + ($j/10) + rem;
        }
    }
}

/* Colors */
.text-act-blue {
    color: $act-blue;
}
.text-act-light-blue {
    color: $act-light-blue;
}
.background-act-blue {
    background-color: $act-blue;
}
.background-act-light-blue {
    background-color: $act-light-blue;
}

.border-left {
    border-left: solid #d7dde9 1px;
    // border-left-color: $act-light-blue;
    // border-width: 1px;
}
.word-break {
    word-break: keep-all;
}
</style>
